const NAVBAR_HEIGHT = 70;

export default theme => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${theme.grey7}`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 70,
      height: '90%',
      zIndex: 2,
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255,255,255,0) 100%)',
      left: 0,
      top: 0,
      marginLeft: '1%'
    }
  },
  segments: {
    overscrollBehavior: 'contain',
    boxSizing: 'border-box',
    display: 'flex',
    margin: '0 20px',
    marginBottom: 5,
    overflow: 'auto',
    padding: 0,
    paddingTop: NAVBAR_HEIGHT - 20,
    position: 'relative',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      height: 7,
      display: 'block',
      background: '#FBFBFB',
      border: `1px solid ${theme.grey5}`,
      borderRadius: 100
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#dedce3',
      borderRadius: 100,
      height: 1,
      '&:hover': {
        background: '#D0CED5'
      }
    }
  },
  inactiveLink: {
    pointerEvents: 'none'
  },

  segmentItem: {
    textDecoration: 'none',
    width: 161,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
    '&.active': {
      cursor: 'default'
    },
    '&:first-of-type': {
      width: 147,
      justifyContent: 'flex-end'
    }
  },
  segmentGroup: {
    display: 'flex',
    position: 'relative'
  },
  expanded: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      background: theme.indigo2,
      left: 0,
      top: 0
    }
  },
  firstExpandedOnly: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: '30px',
      background: theme.indigo2,
      left: 0,
      top: 0
    }
  },
  lastExpandedOnly: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: '30px',
      background: theme.indigo2,
      right: 0,
      top: 0
    }
  },
  stickyButton: {
    position: 'sticky',
    display: 'flex',
    right: '50%',
    transform: 'translate(50%, -9px)',
    zIndex: 2,
    height: 'max-content',
    backgroundColor: 'white',
    borderRadius: '50%',
    '& button div': {
      backgroundColor: theme.indigo2
    }
  },
  absoluteButton: {
    position: 'absolute'
  },
  timeInterval: {
    position: 'relative',
    '&::before': {
      content: '""',
      top: '80px',
      left: 0,
      width: '90px',
      height: '1px',
      zIndex: -1,
      position: 'absolute',
      backgroundColor: '#E5E3EB'
    }
  }
});
