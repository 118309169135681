import { FC, Fragment, WheelEvent, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Path from 'path-to-regexp';
import injectSheet from 'react-jss';

import { ROUTE_INSPECT_TRACE_LINK } from 'constant/routes';
import classNames from 'classnames';
import DynamicIcon from 'components/ui/dynamicIcon';
import { NavLink } from 'react-router-dom';
import styles from './segmentList.style';
import Segment from '../segment';
import { LinkQueryResult } from '../traceInspector.types';
import SegmentGroup from '../segment/segmentGroup';

interface Props {
  classes: any;
  activeSegment: any;
  pulldown: () => JSX.Element;
  links: LinkQueryResult[];
  traceId: string;
}

interface NumberOfAnswersByLinks {
  [key: string]: number;
}
const SegmentList: FC<Props> = ({
  classes,
  activeSegment,
  pulldown,
  links = [],
  traceId
}) => {
  const segmentListRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLDivElement>(null);

  const [expandedGroups, setExpandedGroups] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    groupedActions.forEach((g, i) => {
      if (g.length < 2) return;
      const hasAtiveAction = g.find(
        element => element.linkHash === activeSegment.linkHash
      );
      if (hasAtiveAction) {
        setExpandedGroups(prevState => ({
          ...prevState,
          [i]: !prevState[i]
        }));
      }
    });
  }, []);

  useEffect(() => {
    centerActiveItem();
  }, [expandedGroups]);

  const centerActiveItem = (): void => {
    if (!activeItemRef?.current || !segmentListRef?.current) return;

    const {
      left: activeItemLeft,
      width: activeItemWidth
    } = activeItemRef.current.getBoundingClientRect();
    const { width: listWidth } = segmentListRef.current.getBoundingClientRect();
    const distanceOverCentre: number =
      activeItemLeft + activeItemWidth - 63 - listWidth / 2;

    if (distanceOverCentre > 0) {
      segmentListRef.current.scrollLeft = distanceOverCentre;
    }
  };

  const getNumberOfAnswersByLinks = (
    linksToCheck: LinkQueryResult[]
  ): NumberOfAnswersByLinks =>
    linksToCheck.reduce((answerCounts, link) => {
      const { data } = link;
      if (data && data.commentLinkHash) {
        answerCounts[data.commentLinkHash] =
          (answerCounts[data.commentLinkHash] || 0) + 1;
      }
      return answerCounts;
    }, {});

  const getTimeInterval = (date1: string, date2: string): string => {
    const time2 = moment.utc(date2).local();
    const time1 = moment.utc(date1).local();
    return moment.duration(time2.diff(time1)).humanize();
  };

  const numberOfAnswersByLinks = getNumberOfAnswersByLinks(links);

  const handleWheelEvent = (event: WheelEvent<HTMLDivElement>): void => {
    if (!segmentListRef?.current) return;
    segmentListRef.current.scrollLeft += event.deltaY;
  };

  const groupActions = (actions: LinkQueryResult[]) => {
    const groups = [] as LinkQueryResult[][];
    let currentGroup = [actions[0]];

    for (let i = 1; i < actions.length; i += 1) {
      const currentObj = actions[i];
      const previousObj = actions[i - 1];

      const canBeGrouped = previousObj.action?.canBeGrouped;

      if (currentObj.actionKey === previousObj.actionKey && canBeGrouped) {
        currentGroup.push(currentObj);
      } else {
        groups.push(currentGroup);
        currentGroup = [currentObj];
      }
    }

    groups.push(currentGroup);
    return groups;
  };

  const orderedLinks = links
    .filter(link => link.actionKey !== 'answerComment')
    .slice(0)
    .reverse();

  const toggleGroup = (index: number): void => {
    setExpandedGroups(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const formatedDisplay = (groups: LinkQueryResult[][]) => {
    return groups.map((group, index) => {
      const link = group[0];
      const isLast = index === links.length - 1;
      const reverseIndex = links.length + 1 - index;
      const timeInterval = index
        ? getTimeInterval(links[reverseIndex - 1].createdAt, link.createdAt)
        : null;

      if (group.length > 1) {
        const isExpanded = expandedGroups[index];
        const firstAction = group[0];
        const lastAction = group[group.length - 1];

        return (
          <Fragment key={index}>
            {isExpanded ? (
              <div className={classes.segmentGroup}>
                {group.map((action, i) => (
                  <NavLink
                    key={action.linkHash}
                    to={Path.compile(ROUTE_INSPECT_TRACE_LINK)({
                      id: traceId,
                      linkid: action.linkHash
                    })}
                    className={classNames(
                      classes.segmentItem,
                      classes.expanded,
                      {
                        [classes.lastExpandedOnly]: i === group.length - 1
                      },
                      {
                        [classes.firstExpandedOnly]: i === 0
                      }
                    )}
                    ref={
                      activeSegment.linkHash === action.linkHash
                        ? activeItemRef
                        : null
                    }
                    data-active-index={orderedLinks.findIndex(
                      lks => lks.linkHash === action.linkHash
                    )}
                    draggable={false}
                  >
                    <Segment
                      isActive={activeSegment.linkHash === action.linkHash}
                      link={action}
                      interval={timeInterval}
                      isFirst={
                        orderedLinks.findIndex(
                          lks => lks.linkHash === action.linkHash
                        ) === 0
                      }
                      isLast={
                        orderedLinks.findIndex(
                          lks => lks.linkHash === action.linkHash
                        ) ===
                        orderedLinks.length - 1
                      }
                      answersCount={numberOfAnswersByLinks[action.linkHash]}
                    />
                  </NavLink>
                ))}
                <div className={classes.timeInterval} />
                <div
                  className={classNames(classes.stickyButton, {
                    [classes.absoluteButton]: group.length < 8
                  })}
                >
                  <button onClick={() => toggleGroup(index)}>
                    <DynamicIcon icon="CircleMinus" size={18} />
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={classes.segmentItem}
                onClick={() => toggleGroup(index)}
              >
                <SegmentGroup
                  firstAction={firstAction}
                  lastAction={lastAction}
                  group={group}
                  isFirst={!index}
                  isLast={isLast}
                  interval={timeInterval}
                />
              </div>
            )}
          </Fragment>
        );
      }

      const answersCount = numberOfAnswersByLinks[link.linkHash];
      const isActive = activeSegment.linkHash === link.linkHash;

      return (
        <NavLink
          key={link.linkHash}
          to={Path.compile(ROUTE_INSPECT_TRACE_LINK)({
            id: traceId,
            linkid: link.linkHash
          })}
          className={classes.segmentItem}
          ref={isActive ? activeItemRef : null}
          data-active-index={index}
          draggable={false}
        >
          <Segment
            isActive={isActive}
            link={link}
            interval={timeInterval}
            isFirst={!index}
            isLast={isLast}
            answersCount={answersCount}
          />
        </NavLink>
      );
    });
  };

  const groupedActions = groupActions(orderedLinks);

  return (
    <div className={classes.root}>
      <div
        className={classes.segments}
        ref={segmentListRef}
        onWheel={handleWheelEvent}
      >
        {formatedDisplay(groupedActions)}
        {pulldown}
      </div>
    </div>
  );
};

export default injectSheet(styles)(SegmentList);
